@import "../../_globalColor";

.dev-icons {
  padding-left: 0;
  list-style: none;
  font-size: 3rem;
  margin-bottom: 0px;
  margin-top: 0px;
  text-align: center;
}

.software-skill-inline {
  display: inline-block;
  margin-right: 20px;
  margin-bottom: 20px;
}

.software-skill-inline > i {
  color: $subTitle;
}

.software-skill-inline > i:hover {
  color: $skillsColor;
  transform: scale(1.3);
  transition: 200ms ease;
}
.software-skill-inline > p {
  color: $subTitle;
  font-size: 10px;
}
.software-skill-inline > i:hover ~ p {
  color: $skillsColor;
}

.skill-logo {
  filter: grayscale(100%) opacity(0.67);
  height: 60px;
  width: 60px;

  &:hover {
    filter: none;
    transform: scale(1.3);
    transition: 200ms all;
  }
}
